@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.searchHeader {
  height: 673px;
  width: 100%;
}

.backgroundImage {
  background-image: url("/static/img/backgroundHeader.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchContent {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headerText {
  width: 85%;
  color: #022345;
  font-family: Comfortaa;
  font-size: 42px;
  letter-spacing: -0.2px;
  line-height: 51px;
  width: 357px;
  text-align: center;
  font-weight: 700;
}

.searchbar {
  width: 100%;
  height: 242px;
  margin: 30px 0;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ede8e9;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
}

.divider {
  height: 100%;
  width: 1px;
  background-color: #ede8e9;
}

.searchLabel {
  padding: 0 13px;
  color: #5f5b5b;
  font-weight: 400;
  font-family: Inter;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: left;
}

.searchInputs {
  margin-bottom: 40px;
}

.searchButtonMd {
  display: none;
  width: 100%;
}

.searchButtonMobile {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .headerText {
    width: 85%;
    color: #022345;
    font-family: Comfortaa;
    font-size: 50px;
    letter-spacing: -0.24px;
    line-height: 62px;
    text-align: left;
    font-weight: 700;
  }

  .searchbar {
    width: 100%;
    height: 62px;
    margin: 30px 0;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ede8e9;
    border-radius: 8px;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .searchInputs {
    margin-bottom: 0px;
  }

  .searchButtonMobile {
    display: none;
  }

  .searchButtonMd {
    display: inline-block;
  }
}

@media only screen and (min-width: 992px) {
  .searchContent {
    width: 60%;
  }
}
