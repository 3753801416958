.invoiceList {
  width: 100%;
  height: 100%;
  background: #fafafa;
}

.documentCarousel {
  padding: 15px;
}

.documentCarousel .slick-slide {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.documentCarousel .slick-slide div {
  width: 100%;
  height: 100%;
  padding: 0 20px;
}

.documentCarousel li button {
  margin-top: 5px;
  height: 7px !important;
  background: #5f5b5b !important;
}

.documentCarousel li.slick-active button {
  height: 7px !important;
  background: #eb5e55 !important;
}

.safariMessage {
  width: 100%;
  color: #5f5b5b;
  font-family: Inter;
  font-size: 22px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: center;
  padding: 15% 10%;
}
