.bookingWidget {
  width: 100%;
  margin-top: 20px;
  min-height: 600px;
}

.tags {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: left;
}

.contentBox {
  background-color: #ffffff;
  border: 1px solid #ede8e9;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.calendarTitle {
  color: #3a3335;
  font-family: Inter;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  font-weight: 700;
}

.calendarSubtitle {
  color: #3a3335;
  font-family: Inter;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  font-weight: 600;
}

.calendarText {
  color: #5f5b5b;
  border-radius: 8px;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: center;
  padding: 4px 0px;
  margin-bottom: 5px;
  background: #f4f3f3;
}

.calendarText:hover {
  box-shadow:
    inset -3px 0 0 #eb5e55,
    inset 0 -3px 0 #eb5e55,
    inset 3px 0 0 #eb5e55,
    inset 0 3px 0 #eb5e55;
}

.arrowOutlinedButton {
  padding: 0;
  border: none;
  font-size: large;
  width: 100%;
  height: 100%;
}

.arrowOutlinedContainer {
  height: 400px;
}

.visibleDaysContainer {
  overflow: scroll;
  height: 400px;
}

.cancelButton {
  background-color: #f7f7f7 !important;
  border-radius: 8px !important;
  border: none;
  height: 44px !important;
  padding: 4px 25px !important;
  color: #5f5b5b !important;
  font-family: Inter !important;
  font-size: 14px !important;
  letter-spacing: -0.1px !important;
  line-height: 21px !important;
  text-align: center !important;
  font-weight: 700 !important;
}

.nextButton {
  background-color: #eb5e55 !important;
  border-radius: 8px !important;
  border: none;
  height: 44px !important;
  color: #ffffff !important;
  font-family: Inter !important;
  font-size: 14px !important;
  letter-spacing: -0.1px !important;
  line-height: 21px !important;
  text-align: center !important;
  font-weight: 700 !important;
}

.boxContent {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: left;
}

.centreName {
  color: #3a3335;
  font-family: Inter;
  font-size: 34px;
  letter-spacing: -0.09px;
  line-height: 48px;
  text-align: left;
  font-weight: 800;
}

.checkoutTitle {
  color: #3a3335;
  font-family: Inter;
  font-size: 34px;
  letter-spacing: -0.09px;
  line-height: 48px;
  text-align: center;
  font-weight: 800;
}

.description {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: left;
}

.profileSectionTitle {
  color: #3a3335;
  font-family: Inter;
  font-size: 22px;
  letter-spacing: -0.09px;
  line-height: 32px;
  text-align: left;
  font-weight: 700;
}

.profileSectionContent {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: left;
}

.checkoutText {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 22px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: center;
}

.checkoutButton {
  border: none;
  background-color: #eb5e55 !important;
  border-radius: 10px;
  height: 54px;
  width: 100%;
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: center;
  font-weight: 700;
}

.noAppointmentsAvailable {
  width: 100%;
  color: #5f5b5b;
  font-family: Inter;
  font-size: 22px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: center;
  margin: 10px 10px 30px 10px;
}

@media only screen and (min-width: 992px) {
  .mainProfileContent {
    padding-right: 100px;
  }
}
