@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

/* responsive for phones: */
@media only screen and (max-width: 767px) {
  .footer {
    position: static;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .adress {
    background-color: #022345;
  }
  .logo {
    padding-left: 40px;
    padding-bottom: 42px;
    padding-top: 25px;
  }
  .logo:hover img {
    cursor: pointer;
  }
  .ueber-uns {
    color: #ffffff;
    font-family: Inter;
    font-size: 18px;
    letter-spacing: -0.13px;
    line-height: 33px;
    text-align: left;
    padding-left: 40px;
    padding-bottom: 20px;
  }
  .registrieren-login-a {
    color: #ffffff;
    font-family: Inter;
    font-size: 18px;
    letter-spacing: -0.13px;
    line-height: 33px;
    text-align: left;
    padding-left: 40px;
    padding-bottom: 16px;
  }
  .anmelden {
    color: #5f5b5b;
    font-family: Inter;
    font-size: 18px;
    letter-spacing: -0.1px;
    line-height: 21px;
  }
  .datenschutz {
    background-color: #ffffff;
  }
  .copyright {
    color: #5f5b5b;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  .datenschutz-text {
    color: #3a3335;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 41px;
    font-weight: bold;
  }
  .sprache {
    display: flex;
    margin-top: 23px;
    margin-left: 40px;
    font-weight: bold;
  }
  .material-symbols-sharp {
    font-variation-settings:
      "FILL" 0,
      "wght" 400,
      "GRAD" 0,
      "opsz" 48,
      "site" 100;
    font-size: 18px;
  }
}

/* responsive for tablets: */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .footer {
    position: static;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .adress {
    background-color: #022345;
  }
  .logo {
    text-align: left;
    margin-left: 39px;
    padding-bottom: 42px;
    padding-top: 24px;
  }
  .logo:hover img {
    cursor: pointer;
  }
  .ueber-uns {
    margin-left: 39px;
    padding-top: 42px;
    padding-bottom: 63px;
    color: #ffffff;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 33px;
    text-align: left;
  }
  .registrieren-login-a {
    margin-left: 50px;
    color: #ffffff;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 33px;
    text-align: left;
  }
  .anmelden {
    color: #5f5b5b;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 21px;
    text-align: left;
  }
  .datenschutz {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  .copyright {
    color: #5f5b5b;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 21px;
    text-align: left;
    width: 100%;
    margin-left: 20px;
  }
  .datenschutz-text {
    display: flex;
    color: #3a3335;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 21px;
    text-align: left;
    font-weight: bold;
  }
  .sprache {
    display: flex;
    color: #3a3335;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    padding-left: 25px;
  }
  .material-symbols-sharp {
    font-variation-settings:
      "FILL" 0,
      "wght" 400,
      "GRAD" 0,
      "opsz" 48,
      "site" 100;
    font-size: 17px;
  }
}

/* responsive for desktop: */
@media only screen and (min-width: 1024px) {
  .footer {
    position: static;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .adress {
    background-color: #022345;
  }
  .logo {
    text-align: left;
    margin-left: 39px;
    padding-bottom: 42px;
    padding-top: 24px;
  }
  .logo:hover img {
    cursor: pointer;
  }
  .ueber-uns {
    margin-left: 39px;
    padding-top: 42px;
    padding-bottom: 63px;
    color: #ffffff;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 33px;
    text-align: left;
  }
  .registrieren-login-a {
    margin-left: 50px;
    color: #ffffff;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 33px;
    text-align: left;
  }
  .anmelden {
    color: #5f5b5b;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 21px;
    text-align: left;
  }
  .datenschutz {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  .copyright {
    color: #5f5b5b;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 21px;
    text-align: left;
    width: 100%;
    margin-left: 20px;
  }
  .datenschutz-text {
    display: flex;
    color: #3a3335;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 21px;
    text-align: left;
    font-weight: bold;
  }
  .sprache {
    display: flex;
    color: #3a3335;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    padding-left: 25px;
  }
  .material-symbols-sharp {
    font-variation-settings:
      "FILL" 0,
      "wght" 400,
      "GRAD" 0,
      "opsz" 48,
      "site" 100;
    font-size: 17px;
  }
}
