.footerPageContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 3%;
  margin-bottom: 5%;
}

.footerPageInnerContainer {
  width: 65%;
}

.footerPageTitle {
  font-size: 40px;
  color: #0e597c;
}

.title {
  font-size: 25px;
  color: #0e597c;
  text-align: center;
}

.bulletPoints {
  margin-top: 7px;
}

.bulletPointsNestLi {
  padding-left: 20px;
}

.smallTitle {
  font-size: 17px;
  color: #0e597c;
  margin-bottom: 7px;
}
.helpFrame {
  height: 65vw;
  width: 100vw;
  overflow: hidden;
}
