@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
@import url("https://rsms.me/inter/inter.css");

.floatingButtonBottom {
  position: absolute;
  width: 172px;
  top: 430px;
  right: 0px;
  margin-right: 10px;
}

.mdOnlyGallery {
  display: none;
}

@media only screen and (min-width: 650px) {
  .mdOnlyGallery {
    display: inline-block;
  }

  .mobileOnlyGallery {
    display: none;
  }
}
