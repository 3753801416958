@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

.App {
  scrollbar-width: none;
}

.pageContainer {
  padding: 0 30px;
  margin-top: 25px;
}

#root {
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.blueBackgroundContainer {
  background-color: #eff6fb;
  width: 100%;
  height: 638px;
}

.cardTitle {
  color: #3a3335 !important;
  font-family: Comfortaa !important;
  font-size: 16px !important;
  line-height: 24px !important;
  width: 469px !important;
  text-align: left;
}

.cardDescription {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  width: 469px;
  text-align: left;
}

.suchen {
  color: #3a3335;
  font-family: Comfortaa;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
}

.ant-carousel .slick-prev {
  color: #ffffff;
  left: -45px;
  width: 40px;
  height: 40px;
}

.ant-carousel .slick-prev:focus {
  color: #ffffff;
  left: -45px;
  width: 40px;
  height: 40px;
}

.ant-carousel .slick-prev:hover {
  color: #e8e8e8;
  left: -45px;
  width: 40px;
  height: 40px;
}

.ant-carousel .slick-next {
  color: #ffffff;
  right: -45px;
  width: 40px;
  height: 40px;
}

.ant-carousel .slick-next:focus {
  color: #ffffff;
  right: -45px;
  width: 40px;
  height: 40px;
}

.ant-carousel .slick-next:hover {
  color: #e8e8e8;
  right: -45px;
  width: 40px;
  height: 40px;
}

.ant-carousel .anticon {
  font-size: 40px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mdOnly {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 768px) {
  .mdOnly {
    display: inline-block;
  }

  .mobileOnly {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .pageContainer {
    padding: 0 75px;
  }
}

@media only screen and (min-width: 1200px) {
  .pageContainer {
    padding: 0 152px;
  }
}

.ith_timeSlot {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.ith_timeBox {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 500;
  font-size: 18px;
  margin: 5px;
  transition:
    box-shadow 0.3s,
    color 0.3s;
  cursor: default;
  background-color: #c6c4c4;
  opacity: 0.6;
  z-index: 1;
}

.ith_timeBox.ith_available {
  opacity: 1;
  background-color: #ffe3da;
  color: #4a4a4a;
  cursor: pointer;
}

.ith_timeBox.ith_selected {
  background: #e74c3c !important;
  color: #fff !important;
  opacity: 1;
}

.ith_timeBox.ith_highlighted {
  background: rgba(223, 71, 98, 100);
}

.ith_timeBox.ith_smallPicker {
  width: 35%;
  padding: 20px;
}

.ith_timeBox.ith_available:hover {
  box-shadow: 0 0 11px rgba(223, 71, 98, 100);
}

.ith_timeBox:first-child {
  margin-top: 24px;
}

.ith_timeBox:last-child {
  margin-bottom: 24px;
}

.ith_emptySlot {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  word-break: break-word;
}
@media (max-width: 1350px) {
  .ith_timeBox {
    font-size: 14px;
  }
}
