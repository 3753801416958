@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
@import url("https://rsms.me/inter/inter.css");

.card-Container {
  padding-left: 10.4%;
  padding-right: 10.4%;
  padding-bottom: 87px;
}

.subTitle {
  padding-left: 10.4%;
  padding-top: 25px;
  margin-bottom: 39px;
  color: #022345;
  font-family: Comfortaa;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: -0.09px;
  line-height: 36px;
  text-align: left;
}

.twocardContainer {
  padding-left: 10.4%;
  padding-right: 5.3%;
}

.suchen {
  color: #3a3335;
  font-family: Comfortaa;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
}

.background-container {
  background-color: #eff6fb;
  border-radius: 0 0px 200px 0px;
  padding-bottom: 98px;
}

@media only screen and (max-width: 768px) {
  .card-Container,
  .twocardContainer {
    padding-left: 0;
    padding-right: 0;
    align-items: center;
  }

  .card-Container .ant-card,
  .twocardContainer .ant-card {
    width: 75% !important;
    height: auto !important;
  }

  .subTitle {
    padding-left: 12.5%;
  }

  .background-container {
    border-radius: 0 0px 150px 0px;
  }
}
