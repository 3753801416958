.Dashboard {
  width: 100%;
  height: 100%;
  background: #fafafa;
}

.numberBox {
  height: 125px;
  background: white;
  border: 1px #f0f0f0 solid;
  border-radius: 2px;
  text-align: center;
}

.numberBoxTop {
  height: 100px;
  padding: 10% 0px 0px 0px;
  font-size: 40px;
}

.numberBoxBottom {
  height: 25px;
}

@media only screen and (min-width: 576px) {
  .numberBox {
    height: 185px;
    background: white;
    border: 1px #f0f0f0 solid;
    border-radius: 2px;
  }

  .numberBoxTop {
    height: 150px;
    padding: 15% 0px 0px 0px;
    font-size: 50px;
  }

  .numberBoxBottom {
    height: 35px;
  }
}

@media only screen and (min-width: 768px) {
  .numberBox {
    height: 300px;
    background: white;
    border: 1px #f0f0f0 solid;
    border-radius: 2px;
  }

  .numberBoxTop {
    height: 250px;
    padding: 20% 0px 0px 0px;
    font-size: 60px;
  }

  .numberBoxBottom {
    height: 50px;
  }
}
