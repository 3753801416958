.class-container {
  padding: 0 30px;
  margin-bottom: 5px;
  width: 100%;
  border-radius: 14px 14px 0 0;
}

.maps-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.resultsFor {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: left;
}

@media only screen and (min-width: 768px) {
  .maps-container {
    height: 1000px;
  }

  .class-container {
    border-radius: 0;
  }

  .resultsFor {
    color: #3a3335;
    font-family: Inter;
    font-size: 34px;
    letter-spacing: -0.09px;
    line-height: 48px;
    text-align: left;
    font-weight: 700;
  }
}

@media only screen and (min-width: 992px) {
  .class-container {
    padding-left: 0 75px;
  }
}

@media only screen and (min-width: 1200px) {
  .class-container {
    padding-left: 0 152px;
  }
}
