.profile {
  width: 100%;
}

.tags {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: left;
}

.contentBox {
  background-color: #ffffff;
  border: 1px solid #ede8e9;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.boxTitle {
  color: #3a3335;
  font-family: Inter;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  font-weight: 700;
}

.boxContent {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: left;
}

.centreName {
  color: #3a3335;
  font-family: Inter;
  font-size: 34px;
  letter-spacing: -0.09px;
  line-height: 48px;
  text-align: left;
  font-weight: 800;
}

.description {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: left;
  white-space: pre-wrap;
}

.profileSectionTitle {
  color: #3a3335;
  font-family: Inter;
  font-size: 22px;
  letter-spacing: -0.09px;
  line-height: 32px;
  text-align: left;
  font-weight: 700;
}

.profileSectionContent {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: left;
}

@media only screen and (min-width: 992px) {
  .mainProfileContent {
    padding-right: 100px;
  }
}
