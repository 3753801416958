.settings {
  width: 100%;
  height: 100%;
  background: #fafafa;
  padding: 15px 15px;
}

.authFieldBox {
  background-color: #ffffff;
  border: 1px solid #ede8e9;
  border-radius: 8px;
  height: 72px;
  padding: 10px 15px;
  margin-bottom: 5px;
}

.authFieldLabel {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: left;
}

.authResetPasswordButton {
  border: none;
  color: #eb5e55;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  font-weight: 700;
  text-align: right;
  display: contents;
}

.authLoginButton {
  border: none;
  background-color: #eb5e55 !important;
  border-radius: 10px;
  height: 54px;
  width: 100%;
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: center;
  font-weight: 700;
}

.authRegisterLinkButton {
  border: none;
  color: #5f5b5b;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  display: contents;
}

.errorMessage {
  border: none;
  color: #eb5e55;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
