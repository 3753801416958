@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://rsms.me/inter/inter.css");

.bg {
  background-color: #ffffff;
  border: 1px solid #ede8e9;
  border-radius: 6px;
  width: 100%;
  height: 100px;
}

.img {
  background-size: 100%;
  border-radius: 5px 0 0 5px;
  width: 100%;
  height: 170px;
}

.placeholderImage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgrey;
  font-size: xx-large;
  color: #3a3335;
  border-radius: 5px 5px;
  width: 76px;
  height: 76px;
}

.title {
  color: #3a3335;
  font-family: Inter;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  font-weight: bold;
}

.providerName {
  color: #3a3335;
  font-family: Inter;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
}

.content-Container {
  padding-left: 28px;
}

.services {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: left;
}

.praxis-abc-luzern {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: left;
}

.listingProviderName {
  color: #3a3335;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: left;
  font-weight: 700;
}

.listingCentreName {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: left;
}

.listingText {
  color: #3a3335;
  font-family: Inter;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: left;
  font-weight: 700;
}

.listingDate {
  color: #3a3335;
  font-family: Inter;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: left;
  margin-left: 5px;
}

.listingButton {
  color: #eb5e55;
  font-family: Inter;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: left;
  font-weight: 700;
}

.termine-ab {
  color: #3a3335;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  font-weight: bold;
}

.datum {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  padding-left: 5px;
}

.distance {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
}

.averageRating {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
}

.searchListingLink:hover {
  background-color: #eb5e552b;
}

.ant-pagination .ant-pagination-item {
  border-color: #ffffff;
}

.ant-pagination .ant-pagination-item:hover {
  color: rgb(255, 255, 255);
}

.ant-pagination .ant-pagination-item-active {
  color: rgb(255, 255, 255);
  font-weight: 500;
  background: #eb5e55;
  border-color: #eb5e55;
}

.ant-pagination .ant-pagination-item-active a {
  color: #ffffff;
}

.ant-pagination .ant-pagination-item-active:hover a {
  color: rgb(255, 255, 255);
  font-weight: 500;
  background: #eb5e55;
  border-color: #eb5e55;
}

.ant-list .ant-list-pagination {
  text-align: left;
}

.mobileOnly {
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  .mdOnly {
    display: inline-block;
  }

  .mobileOnly {
    display: none;
  }

  .bg {
    height: 171px;
  }

  .placeholderImage {
    height: 97%;
    width: 100%;
    border-radius: 5px 0 0 5px;
    font-size: xxx-large;
  }
}
