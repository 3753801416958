.ErrorLabel {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 16px;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: center;
}

.ErrorButton {
  border: 1px solid #ede8e9;
  border-radius: 8px;
  color: #5f5b5b;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  display: contents;
}
