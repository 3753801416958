.pageHeader {
  width: 100%;
  height: 75px;
  padding: 0 39px;
  box-sizing: border-box;
}

.headerLogo {
  display: flex;
}

.headerLogo a {
  display: flex;
  max-width: 200px;
}

.headerLink {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  text-align: center;
  font-weight: 700;
  margin-right: 10px;
}

.headerSignUpDashboard {
  background-color: #eb5e55 !important;
  border-radius: 8px !important;
  border: none;
  height: 44px !important;

  color: #ffffff !important;
  font-family: Inter !important;
  font-size: 14px !important;
  letter-spacing: -0.1px !important;
  line-height: 21px !important;
  text-align: center !important;
  font-weight: 700 !important;
}

.headerSignInLogout {
  background-color: #f7f7f7 !important;
  border-radius: 8px !important;
  border: none;
  height: 44px !important;
  padding: 4px 25px !important;

  color: #5f5b5b !important;
  font-family: Inter !important;
  font-size: 14px !important;
  letter-spacing: -0.1px !important;
  line-height: 21px !important;
  text-align: center !important;
  font-weight: 700 !important;
}

.phoneSignInSignUp {
  display: none;
}

.normalSignInSignUp {
  display: flex;
  justify-content: end;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .phoneSignInSignUp {
    display: flex;
    justify-content: end;
  }
  .normalSignInSignUp {
    display: none;
  }
}
