@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
@import url("https://rsms.me/inter/inter.css");

.cardTitle {
  color: #3a3335;
  font-family: Comfortaa;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  width: 469px;
  text-align: left;
}

.cardDescription {
  color: #5f5b5b;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  width: 469px;
  text-align: left;
}

.cardMeta {
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  width: 100%;
  text-align: left;
}

.smallCardImg {
  height: 176px;
}

@media only screen and (max-width: 650px) {
  .cardTitle {
    text-align: left !important;
    max-width: 100% !important;
  }

  .cardDescription {
    text-align: left !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 650px) {
  .cardTitle {
    text-align: left !important;
    max-width: 100% !important;
  }

  .cardDescription {
    text-align: left !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .smallCardImg {
    height: auto;
  }
}
